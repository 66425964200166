import FramerHero from '@/components/framer/hero';
import Logos from '@/components/framer/logos';
import { MyFooter, MyNavbar, MyPricing, MySEO, SignupModal } from '@/components/specific';
import { PageContainer, RootPageContainer } from 'beskar/landing';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { GradientBg } from '@/components/bg';
import { TikTokFaqs } from '@/components/faq';
import { TTSForm } from '@/components/tts';
import { useBuy } from '@/lib/hooks';
function Page({}) {
  const {
    status,
    data: session
  } = useSession();
  const router = useRouter();
  const buy = useBuy();
  return <div className='text-gray-100 dark bg-gray-900 '>
            
            <GradientBg />
            <MySEO {...{
      title: 'Gesserit - TTS text-to-speech online for your videos',
      canonicalPath: '/',
      description: 'Generate voices from text for videos. Can generate the girl siri voice, robot voice, Rocket and all other TikTok voices.'
    }} />

            <SignupModal />
            <RootPageContainer className='!min-h-screen'>
                <MyNavbar />

                <div className='flex flex-col items-center'>
                    <FramerHero.Responsive heading='Realistic voice generator for your videos' variants={{
          lg: 'Variant 1',
          base: 'mobile'
        }} className='!max-w-full' />
                </div>
                <div className='flex opacity-70 flex-col items-center'>
                    <Logos className='!max-w-[100%]' />
                </div>
                <PageContainer dontContain>
                    <div className='flex flex-col items-center'>
                        <TTSForm />
                    </div>
                </PageContainer>
                <TikTokFaqs />

                <MyPricing />
                <div className='grow'></div>
                <MyFooter />
            </RootPageContainer>
        </div>;
}
export default Page;
export const getStaticProps = async ctx => {
  const locale = ctx.params?.locale || 'en';
  return {
    props: {
      locale
    }
  };
};